import React, { useState, useEffect, useCallback } from 'react';

const DynamicInputFields = ({ maxFields = 5, onFieldsChange, initialFields = [] }) => {
  const [inputFields, setInputFields] = useState(initialFields);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    onFieldsChange(inputFields);
  }, [inputFields, onFieldsChange]);

  const validateFields = useCallback(() => {
    return inputFields.every((url) => url.trim() !== "");
  }, [inputFields]);

  useEffect(() => {
    validateFields();
  }, [inputFields, validateFields]);

  const handleAddField = () => {
    const hasEmptyField = inputFields.some((url) => url.trim() === "");

    if (!hasEmptyField) {
        if (inputFields.length < maxFields) {
            setInputFields([...inputFields, '']);
        }
    } else {
        const error = `Por favor, primero complete todos los campos vacíos.`;
        setErrorMsg(error);
    }
  };

  const handleInputChange = (index, event) => {
    setErrorMsg('');
    const newInputFields = [...inputFields];
    newInputFields[index] = event.target.value;
    setInputFields(newInputFields);
  };

  const handleRemoveField = (index) => {
    setErrorMsg('');
    const newInputFields = inputFields.filter((_, i) => i !== index);
    setInputFields(newInputFields);
  };

  const handleClearFields = () => {
    setErrorMsg('');
    setInputFields([]);
  };

  return (
    <div>
      {inputFields.map((field, index) => (
        <div key={index} className='Form-InputUrl'>
          <input
            type="url"
            className='Form-Input'
            value={field}
            placeholder="https://example.com"
            onChange={(event) => handleInputChange(index, event)}
          />
          <button type="button" className="Button_remove" onClick={() => handleRemoveField(index)}>
            x
          </button>
        </div>
      ))}
      {errorMsg && <div className="Form-FileError">{errorMsg}</div>}
      <div className="Form-ButtonContainer">
        {inputFields.length < maxFields && (
          <button type="button" className="Button Button_tertiary" onClick={handleAddField}>
            + Añadir
          </button>
        )}
        {inputFields.length > 0 && (
          <button type="button" className="Button Button_secondary" onClick={handleClearFields}>
            Limpiar Urls
          </button>
        )}
      </div>
    </div>
  );
};

export default DynamicInputFields;