
export function sendAIMessage(context, keywords, siteExample) {
  const token = localStorage.getItem("auth-token");
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_BACK_DOMAIN}/getAIResponse`, {
      method: 'POST',
      body: JSON.stringify(
        {
          context: context,
          keywords: keywords,
          siteExample: siteExample
        }
      ),
      headers: {
        Accept: 'application/json',
        'access-token': `${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function getFormatArticle(article, siteExample) {
  const token = localStorage.getItem("auth-token");
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_BACK_DOMAIN}/getAIFormatResponse`, {
      method: 'POST',
      body: JSON.stringify(
        {
          article: article,
          siteExample: siteExample
        }
      ),
      headers: {
        Accept: 'application/json',
        'access-token': `${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function getDataDocument(files, siteExample) {
  const token = localStorage.getItem("auth-token");
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    files.forEach(file => {
      formData.append('files', file?.file);
    });

    formData.append('siteExample', siteExample);

    fetch(`${process.env.REACT_APP_BACK_DOMAIN}/getDocumentsData`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'access-token': `${token}`,
      },
      body: formData,
    })
      .then(res => res.json())
      .then(data => {
        data.files = data.files.map((file, index) => {
          const originalFile = files[index];
          if (originalFile.name) file.name = originalFile.name;
          if (originalFile.position) file.position = originalFile.position;
          return file;
        });
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export const submit = async (e, options = {}, setStateFunctions) => {
  e.preventDefault();

  const {
    setStateBar,
    setProgress,
    setDataResponse,
    setStateButton,
    setLoading
  } = setStateFunctions;

  try {
    setStateBar(true);
    setProgress(0);
    setDataResponse([]);
    setStateButton(false);
    setLoading("Generando artículo, por favor espere...");

    let webDataResponse = "";
    let fileData = "";
    let transcriptions= "";

    const allFiles= options?.fileFields.concat(options?.audioFields);


    if (options?.fields.length > 0) {
      webDataResponse = await getWebData(options.fields);
      setProgress(20);
    }
    if (allFiles.length > 0) {
      fileData = await getDataDocument(allFiles, options?.userData?.user?.role);
      setProgress(40);
    }

    const finalContext = {};

    if (options?.textContentSource) {
      finalContext.textContent = { name: "TextoManual", content: options.textContentSource };
    }
    if (webDataResponse) {
      finalContext.urlContent = webDataResponse;
    }
    if (fileData?.files) {
      finalContext.fileContent = fileData?.files;
      transcriptions = fileData.files.filter((file)=> file.typeFile === "audio/mpeg" || file.typeFile === "audio/wav" );

    }

    const response = await sendAIMessage(finalContext, options.term, options?.userData?.user?.role);
    const responseFormat = await getFormatArticle(response?.answer, options?.userData?.user?.role);
    setDataResponse(prevData => [...prevData, {
      ID: 1,
      Keywords: options.term,
      Articulo: response?.answer,
      ArticuloFormato: responseFormat?.answer,
      Referencias: options.fields,
      Transcripciones: transcriptions
    }]);
    setProgress(100);
    setLoading("Artículo generado");
    setStateButton(true);

  } catch (err) {
    alert("ha ocurrido un error");
    console.log("error", err);
  }
};


export function getDataTrends() {
  const token = localStorage.getItem("auth-token");
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_BACK_DOMAIN}/realTimeTrends?geo=CO&category=e`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'access-token': `${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function getWebData(urls) {
  const token = localStorage.getItem("auth-token");
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_BACK_DOMAIN}/getWebInfo`, {
      method: 'POST',
      body: JSON.stringify(
        {
          urls: urls
        }
      ),
      headers: {
        Accept: 'application/json',
        'access-token': `${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function getWebSearchSerp(tema, isNews) {
  const token = localStorage.getItem("auth-token");
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_BACK_DOMAIN}/getSearchSerp`, {
      method: 'POST',
      body: JSON.stringify(
        {
          tema: tema,
          isNews: isNews
        }
      ),
      headers: {
        Accept: 'application/json',
        'access-token': `${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(data => {
        resolve(data);
      })
      .catch(err => reject(err));
  });
}

export function extractUrl(array) {
  const arraySubElement = [];
  array.forEach(subElement => {
    if (arraySubElement.length < 5) {
      arraySubElement.push(subElement?.url);
    }
  });
  return arraySubElement;
}

export function validateToken(token) {
  if (token) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_BACK_DOMAIN}/users/tokenIsValid`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'x-auth-token': `${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(data => {
          resolve(data);
        })
        .catch(err => reject(err));
    });
  }

}