import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";
import { validateToken } from "../../utilities/functions";

export default function UserFilter() {
    const [displayName, setDisplayname] = useState("");
    const [role, setRole] = useState("");
    const [status, setStatus] = useState("");
    const [password, setPassword] = useState("");
    const [passwordCheck, setPasswordCheck] = useState("");
    const [error, setError] = useState("");

    const { userData } = useContext(UserContext);
    const history = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');

    useEffect(() => {
        const authToken = localStorage.getItem("auth-token");
        if (authToken) {
            validateToken(authToken).then(result => {
                if (result?.error) {
                    localStorage.setItem("auth-token", "");
                    localStorage.setItem("user", "");
                    history('/admin/login');
                }
            });
        }
        if (!authToken) {
            history('/admin/login');
        }

        async function fetchData() {
            const authToken = localStorage.getItem("auth-token");
            const userRes = await Axios.get(`${process.env.REACT_APP_BACK_DOMAIN}/users/findone/${id}`,
                {
                    headers: {
                        "x-auth-token": authToken,
                        "Content-Type": "application/json",
                    },
                });
            setDisplayname(userRes.data.displayName || "");
            setRole(userRes.data.role || "");
            setStatus(userRes.data.status || "");
        }
        fetchData();
    }, [history, id]);

    const submit = async (e) => {
        e.preventDefault();
        try {
            const authToken = localStorage.getItem("auth-token");
            if (userData.user && id) {
                const modificator = userData.user.displayName;
                const newDataUser = password && passwordCheck ?
                    { id, displayName, password, passwordCheck, role, status, modificator } :
                    { id, displayName, role, status, modificator };
                await Axios.put(`${process.env.REACT_APP_BACK_DOMAIN}/users/update`, newDataUser,
                    {
                        headers: {
                            "x-auth-token": authToken,
                            "Content-Type": "application/json",
                        },
                    });
                alert("Usuario actualizado correctamente");
                history('/admin/register');
            }
        } catch (err) {
            if (err.response?.data?.msg) {
                setError(err.response.data.msg);
            }
        }
    };

    return (
        <div className="page">
            <div className="container">
                <h2>Actualizar Usuario</h2>
                {error && (
                    <ErrorNotice message={error} clearError={() => setError(undefined)} />
                )}
                <form className="Form" onSubmit={submit} autoComplete="off">
                    <div className="row">
                        <div>
                            <div className="Form-Field">
                                <label className="Form-Label" htmlFor="register-nombre">Nombre de Usuario:</label>
                                <input
                                    className="Form-Input"
                                    id="display_name"
                                    name="displayname"
                                    onChange={(e) => setDisplayname(e.target.value)}
                                    type="text"
                                    value={displayName}
                                />
                            </div>
                            {userData.user && userData.user.role === 'administrador' && (
                                <>
                                    <div className="Form-Field">
                                        <label className="Form-Label" htmlFor="role">Rol: </label>
                                        <select className="Form-Input" name="role" id="role" value={role} onChange={(e) => setRole(e.target.value)}>
                                            <option value="">--- Seleccione una opción ---</option>
                                            <option value="administrador">Administrador</option>
                                            <option value="el-espectador">Redacción EE</option>
                                            <option value="revista-cromos">Redacción Cromos</option>
                                            <option value="revista-vea">Redacción Vea</option>
                                        </select>
                                    </div>
                                    <div className="Form-Field">
                                        <label className="Form-Label" htmlFor="status">Estado: </label>
                                        <select className="Form-Input" name="status" id="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                                            <option value="">--- Seleccione una opción ---</option>
                                            <option value={true}>Activo</option>
                                            <option value={false}>Inactivo</option>
                                        </select>
                                    </div>
                                </>
                            )}
                            <div className="Form-Field">
                                <label className="Form-Label" htmlFor="pwd">Password:</label>
                                <input
                                    className="Form-Input"
                                    type="password" id="pwd" name="pwd"
                                    minLength="5" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div className="Form-Field">
                                <label className="Form-Label" htmlFor="check_pwd">Verificar Password:</label>
                                <input
                                    className="Form-Input"
                                    type="password" id="check_pwd" name="check_pwd"
                                    minLength="5" value={passwordCheck} onChange={(e) => setPasswordCheck(e.target.value)} />
                            </div>
                            <input className="Button Button_primary" type="submit" value="Actualizar" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
