import React, { useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import Form from "../pages/Form";

export default function Home() {
    const { userData } = useContext(UserContext);
    const history = useNavigate();

    useEffect(() => {
        if (userData.user === undefined) return; // Esperar hasta que userData sea inicializado
        if (!userData.user) history('/admin/login');
    }, [userData, history]);

    return (
        <div className="page">
            {userData.user === undefined ? null : <Form />}
        </div>
    )
}