
const Tabla = (props) => {
  console.log("Referencias", props.data?.[0]?.Referencias);
  return (
    <div>
      <h2>Artículo</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Keywords</th>
            <th>Artículo</th>
            <th>Artículo sin citas</th>
            {props.data?.[0]?.Referencias.length>0 &&<th>Referencias</th>}
            {props.data?.[0]?.Transcripciones.length>0 && <th>Transcripciones</th>}
          </tr>
        </thead>
        <tbody>
          <tr key={props.data?.[0]?.ID}>
            <td>{props.data?.[0]?.ID}</td>
            <td><div className='TableCell TableCell-Keywords'>{props.data?.[0]?.Keywords}</div></td>
            <td><div className='TableCell TableCell-Articulo'>{props.data?.[0]?.Articulo}</div></td>
            <td><div className='TableCell TableCell-ArticuloFormat'>{props.data?.[0]?.ArticuloFormato}</div></td>
            {props.data?.[0]?.Referencias.length>0 &&
              <td>
              <div className='TableCell TableCell-Referencias'>
                <ul>
                  {props.data?.[0]?.Referencias.map((item) => (
                    <li key={item}><a href={item} target='_blank' rel="noreferrer">{item}</a></li>
                  ))}
                </ul>
              </div>
            </td>
            }
            {props.data?.[0]?.Transcripciones.length>0 && <td>
              <div className='TableCell TableCell-Transcripcion'>
                <ul>
                  {props.data?.[0]?.Transcripciones.map((item) => (
                    <li key={item?.filename}>
                      <p>Archivo: {item?.filename}</p>
                      <p>Transcripción: {item?.content}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </td>}
            
          </tr>

        </tbody>
      </table>
    </div>
  );
}

export default Tabla;