import React, { useState, useContext } from 'react';
import { submit } from '../../utilities/functions';
import Excel from '../general/Excel';
import Progressbar from '../general/Progressbar';
import Tabla from '../general/Tabla';
import DynamicInputFields from '../general/DynamicInputFields';
import DynamicFileFields from '../general/DynamicFileFields';
import UserContext from "../../context/UserContext";
import ErrorNotice from "../misc/ErrorNotice";

const Form = () => {
  const [dataResponse, setDataResponse] = useState([]);
  const [stateButton, setStateButton] = useState(false);
  const [stateBar, setStateBar] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(null);
  const [textContentSource, setTextContentSource] = useState("");
  const [term, setTerm] = useState("");
  const [fields, setFields] = useState([]);
  const [fileFields, setFileFields] = useState([]);
  const [audioFields, setAudioFields] = useState([]);
  const [error, setError] = useState(null);
  const [areUrlsValid, setAreUrlsValid] = useState(false);

  const { userData } = useContext(UserContext);

  const handleFieldsChange = (newFields) => {
    setFields(newFields);
    setAreUrlsValid(newFields.every((url) => url.trim() !== ""));
  };
  const handleFileFieldsChange = (newFields) => {
    setFileFields(newFields);
  };
  const handleAudioFieldsChange = (newFields) => {
    setAudioFields(newFields);
  };

  const handleBack = () => {
    setStateBar(false);
    setStateButton(false);
  };

  const handleChangeTextContent = (event) => {
    setTextContentSource(event.target.value);
  };

  const validateFileFields = (fields, isAudio) => {
    return fields.every(file => {
      if (!file.file) return false;
      if (isAudio && (!file.name.trim() || !file.position.trim())) return false;
      return true;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const areFileFieldsValid = validateFileFields(fileFields, false);
    const areAudioFieldsValid = validateFileFields(audioFields, true);

    if (
      term.trim() === "" ||
      !(textContentSource.trim() !== "" ||
        fields.some((url) => url.trim() !== "") ||
        fileFields.some((file) => file.file) ||
        audioFields.some((file) => file.file)) ||
      !areUrlsValid || 
      !areFileFieldsValid ||
      !areAudioFieldsValid
    ) {
      setError("Por favor llene los campos obligatorios y asegúrese de que todos los archivos tengan nombre y cargo.");
      return;
    }

    const options = {
      term,
      textContentSource,
      fields,
      userData,
      fileFields: fileFields.map(file => {
        const { name, position, ...rest } = file;
        return rest;
      }),
      audioFields: audioFields.map(file => ({
        ...file,
        file: file.file,
        name: file.name,
        position: file.position
      }))
    };

    const setStateFunctions = {
      setStateBar,
      setProgress,
      setDataResponse,
      setStateButton,
      setLoading
    };

    try {
      await submit(e, options, setStateFunctions);
    } catch (err) {
      console.error("Error during submit", err);
    }
  };

  return (
    <div className="App">
      {!stateBar && (
        <form className="Form Form_generator" id="form" onSubmit={handleSubmit}>
          <div className="Form-Container">
            {error && (
              <ErrorNotice message={error} clearError={() => setError(undefined)} />
            )}
            <fieldset>
              <div className="Form-Field">
                <label htmlFor="dataToBrowse" className="Form-Label">Temática a buscar *:</label>
                <input
                  id="dataToBrowse"
                  type="text"
                  className="Form-Input"
                  value={term}
                  onChange={(e) => { setTerm(e.target.value) }}
                  placeholder="Escriba palabras clave a tener en cuenta en la generación del artículo, separadas por coma"
                  autoComplete="off"
                />
              </div>
              <div className="Form-Field">
                <label htmlFor="dataContextText" className="Form-Label">Contexto:</label>
                <textarea
                  id="dataContextText"
                  className="Form-TextArea"
                  value={textContentSource}
                  onChange={handleChangeTextContent}
                  rows="20"
                  cols="60"
                  placeholder="Escriba el texto a tener en cuenta para la generación de la nota"
                  maxLength={5000}
                />
              </div>
              <div className="Form-Field">
                <label className="Form-Label">Urls a usar:</label>
                <DynamicInputFields maxFields={5} onFieldsChange={handleFieldsChange} initialFields={fields} />
              </div>
              <div className="Form-Field">
                <label className="Form-Label">Documentos:</label>
                <DynamicFileFields
                  fileTypes={{
                    "text/plain": 3145728,
                    "application/pdf": 3145728,
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": 3145728,
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": 3145728,
                    "application/csv": 3145728,
                    "text/csv": 3145728
                  }}
                  maxFiles={5}
                  onFieldsChange={handleFileFieldsChange}
                  initialFields={fileFields}
                />
              </div>
              <div className="Form-Field">
                <label className="Form-Label">Audios:</label>
                <DynamicFileFields
                  isAudio={true}
                  fileTypes={{
                    "audio/mpeg": 10485760,
                    "audio/wav": 10485760
                  }}
                  maxFiles={5}
                  onFieldsChange={handleAudioFieldsChange}
                  initialFields={audioFields}
                />
              </div>
            </fieldset>
            <input
              type="submit"
              className="Button Button_primary"
              value={"Generar artículo"}
              disabled={
                term.trim() === "" ||
                !(textContentSource.trim() !== "" ||
                  fields.some((url) => url.trim() !== "") ||
                  fileFields.some((file) => file.file) ||
                  audioFields.some((file) => file.file)) ||
                !areUrlsValid || 
                !validateFileFields(fileFields, false) ||
                !validateFileFields(audioFields, true)
              }
            />
          </div>
        </form>
      )}
      {stateBar && <h3 className="Form-DescriptionLoading">{loading}</h3>}
      {stateBar && <Progressbar value={progress} />}
      <div>
        {stateButton && <Excel data={dataResponse} isSearch={false} />}
      </div>
      {stateButton && (
        <div className='Button-Container'>
          <button className="Button Button_secondary" onClick={handleBack}>Regresar</button>
        </div>
      )}
      {stateButton && <Tabla data={dataResponse} isSearch={false} />}
    </div>
  );
};

export default Form;
