import * as XLSX from "xlsx";

const ExportCSV = (props) => {
  const defaultHeader = ["ID", "Keywords", "Articulo", "ArticuloFormato"];

  const determineDynamicColumns = (data) => {
    let header = [...defaultHeader];
    let includeReferencias = false;
    let includeTranscripciones = false;

    data.forEach((row) => {
      if (row["Referencias"] && row["Referencias"].length > 0) {
        includeReferencias = true;
      }
      if (row["Transcripciones"] && row["Transcripciones"].length > 0) {
        includeTranscripciones = true;
      }
    });

    if (includeReferencias) header.push("Referencias");
    if (includeTranscripciones) header.push("Transcripciones");

    return header;
  };

  const getSheetData = (data, header) => {
    const sheetData = data.map((row) => {
      return header.map((fieldName) => {
        let result = row[fieldName] ? row[fieldName] : "";
        if (fieldName === "Referencias" && row[fieldName]) {
          result = row[fieldName].join("\n");
          if (row[fieldName].length === 0) {
            result = "N.A";
          }
        }
        if (fieldName === "Transcripciones" && row[fieldName]) {
          result = row[fieldName]
            ?.map((audio) => `Archivo: ${audio?.filename}\nTranscripción: ${audio?.content}`)
            ?.join("\n");
        }
        return result;
      });
    });
    sheetData.unshift(header);
    return sheetData;
  };

  const handleDownload = () => {
    const header = determineDynamicColumns(props.data);
    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(getSheetData(props.data, header));
    XLSX.utils.book_append_sheet(libro, hoja, "Articulos");
    setTimeout(() => {
      XLSX.writeFile(libro, "ArticulosGenerados.xlsx");
    }, 1000);
  };

  return <button className="Button Button_primary"  onClick={handleDownload}>Descargar artículo</button>;
};

export default ExportCSV;
