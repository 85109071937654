import React, { useState, useEffect } from "react";

const DynamicFileFields = ({
    isAudio,
    fileTypes,
    maxFiles,
    fieldLabel,
    onFieldsChange,
    initialFields = []
}) => {
    const [fileFields, setFileFields] = useState(initialFields);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        onFieldsChange(fileFields);
    }, [fileFields, onFieldsChange]);

    const handleAddFile = () => {
        const hasEmptyField = fileFields.some(file => !file.file || (isAudio && (!file.name || !file.position)));

        if (!hasEmptyField) {
            if (fileFields.length < maxFiles) {
                setFileFields(prevFiles => [...prevFiles, { file: null, name: '', position: '' }]);
            }
        } else {
            const error = `Por favor complete todos los campos antes de agregar un nuevo archivo.`;
            setErrorMsg(error);
        }
    };

    const handleRemoveFile = (index) => {
        setErrorMsg('');
        const files = fileFields.filter((_, i) => i !== index);
        setFileFields(files);
    };

    const handleClearFiles = () => {
        setErrorMsg('');
        setFileFields([]);
    };

    const handleFileChange = (event, index) => {
        const file = event.target.files[0];
        const fileType = file?.type;

        if (file && file.size < fileTypes[fileType]) {
            setErrorMsg('');
            let fields = [...fileFields];
            fields[index].file = file;
            setFileFields(fields);
        } else {
            event.target.value = '';
            const error = file 
                ? `Peso máximo para ${fileType}: ${fileTypes[fileType] / 1048576} MB.` 
                : 'Seleccione un archivo válido.';
            setErrorMsg(error);
        }
    };

    const handleInputChange = (event, index, field) => {
        let fields = [...fileFields];
        fields[index][field] = event.target.value;
        setFileFields(fields);
    };

    return (
        <div>
            <div>{fieldLabel}</div>
            <div className="Form-Files">
                {fileFields.map((field, index) => (
                    <div key={index} className="Form-FileSelect">
                        <label className="Form-InputFile">
                            Elegir archivo
                            <input
                                style={{ display: 'none' }}
                                type='file'
                                accept={Object.keys(fileTypes).join(', ')}
                                onChange={(event) => handleFileChange(event, index)}
                            />
                        </label>
                        {field.file ? (
                            <div className="Form-File">
                                {isAudio ? (
                                    <div>
                                        {field.file.name}
                                        <div className="Form-Reference">
                                            <div className="Form-Field">
                                                <input
                                                    type="text"
                                                    className='Form-Input'
                                                    value={field.name}
                                                    onChange={(event) => handleInputChange(event, index, 'name')}
                                                    placeholder="Nombre"
                                                />
                                            </div>
                                            <div className="Form-Field">
                                                <input
                                                    type="text"
                                                    className='Form-Input'
                                                    value={field.position}
                                                    onChange={(event) => handleInputChange(event, index, 'position')}
                                                    placeholder="Cargo"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : field.file.name}
                                <button type="button" className="Button_remove" onClick={() => handleRemoveFile(index)}>
                                    x
                                </button>
                            </div>
                        ) : (
                            <div className="Form-FileSubtitle">
                                No se ha seleccionado ningún archivo
                                <button type="button" className="Button_remove" onClick={() => handleRemoveFile(index)}>
                                    x
                                </button>
                            </div>
                        )}
                    </div> 
                ))}
            </div>
            {errorMsg && <div className="Form-FileError">{errorMsg}</div>}
            <div className="Form-ButtonContainer">
                {fileFields.length < maxFiles && (
                    <button type="button" className="Button Button_tertiary" onClick={handleAddFile}>+ Añadir</button>
                )}
                {fileFields.length > 0 && (
                    <button type="button" className="Button Button_secondary" onClick={handleClearFiles}>Remover archivos</button>
                )}
            </div>
        </div>
    );
};

export default DynamicFileFields;
