import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";

export default function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const { setUserData } = useContext(UserContext);
  const history = useNavigate();

  const submit = async (e) => {
    e.preventDefault();
    try {
      const loginUser = { email, password };
      const loginRes = await Axios.post(
        `${process.env.REACT_APP_BACK_DOMAIN}/users/login`,
        loginUser
      );
      setUserData({
        token: loginRes.data.token,
        user: loginRes.data.user
      });
      localStorage.setItem("auth-token", loginRes.data.token);
      localStorage.setItem("user", loginRes.data.user.displayName);
      history("/");
    } catch (err) {
      err.response.data.msg && setError(err.response.data.msg);
    }
  };

  return (
    <div className="page">
      <div className="container">
        <h2>Log In</h2>
        {error && (
          <ErrorNotice message={error} clearError={() => setError(undefined)} />
        )}
        <form className="Form Form_login" onSubmit={submit}>
          <div className="Form-Container">
            <fieldset>
              <div className="Form-Field">
                <label htmlFor="login-email" className="Form-Label">Email</label>
                <input
                  id="login-email"
                  type="email"
                  className="Form-Input"
                  onChange={(e) => setEmail(e.target.value)}
                />

              </div>
              <div className="Form-Field">
                <label htmlFor="login-password" className="Form-Label">Password</label>
                <input
                  id="login-password"
                  type="password"
                  className="Form-Input"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </fieldset>

            <input type="submit" value="Login" className="Button Button_primary" />

          </div>
        </form>
      </div>
    </div>
  );
}
